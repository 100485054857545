<div class="lot-unknown full-height" (keyup.arrowup)="keyboardNav('uparrow')">
  <ng-container *ngIf="false; else elseTemplate">
      Nothing to see here
  </ng-container>
  <ng-template #elseTemplate>
    <div class="lot-unknown-batches full-height">

      <mat-form-field class="lot-unknown-filter">
        <mat-label>Filter BatchId</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Example: HB1234">
      </mat-form-field>

      <mat-table mat-table #table [dataSource]="datasource" matSort class="lot-unknown-table">
        <ng-container matColumnDef="BatchId">
          <th mat-header-cell *matHeaderCellDef>BatchId</th>
          <td mat-cell *matCellDef="let row"> {{row.BatchId}} </td>
        </ng-container>

        <ng-container matColumnDef="IsPublic">
          <th mat-header-cell *matHeaderCellDef>IsPublic</th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox [(ngModel)]="row.IsPublic" (change)="saveBatch(row)">IsPublic</mat-checkbox>&nbsp;
          </td>
        </ng-container>

        <ng-container matColumnDef="Lot">
          <th mat-header-cell *matHeaderCellDef>Isolate</th>
          <td mat-cell *matCellDef="let row">
            <mat-form-field>
              <mat-select (selectionChange)="lotChanged(row)" [(value)]="row.LotId">
                <mat-option *ngFor="let lot of (rawLots | async) | sortBy: 'desc' : 'ArrivalDate'" [value]="lot.LotId">
                  {{lot.LotId}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let row">
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Action</button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="promoteToLot(row)">Promote to Isolate</button>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Attachments">
          <th mat-header-cell *matHeaderCellDef>Attachments</th>
          <td mat-cell *matCellDef="let row">
            <a *ngFor="let attachment of row.Attachments" (click)="showAttachment(attachment)"><img src="/assets/icons/oxygen/22x22/mimetypes/application-pdf.png"/></a>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered, highlighted: row.highlighted}"
          (click)="setCurrentItem($event, row)"
          (mouseover)="row.hovered = true"
          (mouseout)="row.hovered = false"></tr>
      </mat-table>
      <mat-paginator hidden [pageSizeOptions]="[5, 10, 25]"></mat-paginator>

    </div>
    <div class="lot-unknown-attachments full-height">
      <app-attachment-view-edit (onprevious)="previousAttachment($event)" (onnext)="nextAttachment($event)" #pdf [attachment]="currentAttachment"></app-attachment-view-edit>
    </div>

  </ng-template>
 </div>
