import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { Storage } from 'aws-amplify';
import { AttachmentService } from '../services/attachment.service';
import { Attachment } from '../classes/attachment';


@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent extends BaseDialogComponent implements OnInit {

  public files: NgxFileDropEntry[] = [];
  lotId: string;
  batchId: string;
  file: File;

  constructor(
    private attachmentService: AttachmentService,
    public dialogRef: MatDialogRef<AttachmentComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      super(dialogRef);
      this.lotId = data.lotId;
      this.batchId = data.batchId || null;
  }

  ngOnInit() {
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          this.file = file;
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  save() {
    Storage.put(`/upload/${this.file.name}`, this.file, {
      progressCallback(progress) {
           console.log('Uploaded : ', progress);
      },
      contentType: 'image/pdf'
    }).then((result: any) => {
      console.log('Success =>', result);
      const attachment = new Attachment();
      attachment.LotId = this.lotId;
      attachment.BatchId = this.batchId;
      this.close();
    }).catch((err) => {
            console.log('error =>', err);
    });
  }

  cancel() {
    this.close();
  }

}
