import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'except'
})
export class ExceptPipe implements PipeTransform {

  transform(items: any[], excludeProperty: string, excludeValue: string): any {
    if (items instanceof Array) {
      return items.filter(item => item[excludeProperty] !== excludeValue );
    }
    else {
      return items;
    }
  }

}
