
<div id="qwerty-chart" class="container">
  <button class="text-white float-left add-lot" mat-raised-button color="accent" aria-label="Add Isolate" (click)="addLot()">
      <mat-icon class="text-light">add</mat-icon>
  </button>
  <div class="row full-height">
    <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="selectedTabChange($event)" mat-stretch-tabs class="mx-auto" style="width: 100%;">
      <div class="tab-wrap bg-dark">
        <mat-tab class="bg-dark chart-tab" *ngFor="let lot of rawLots | async | except : 'LotId': 'UNKNOWN' | sortBy :'ArrivalDate':'desc'">
          <ng-template mat-tab-label class="color-white d-flex justify-content-between">
            <div [matTooltip]="lot.ArrivalDate | date:'medium'">
              <span [matBadge]="lot.Batches?.length" matBadgeOverlap="false" matBadgePosition="above after" matBadgeColor="warn" class="text-white">{{lot.LotId}}</span>
            </div>

            <div class="">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More Options">
                  <mat-icon class="text-white">arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="deleteLot(lot)">
                    <mat-icon class="text-gray">delete</mat-icon>
                    <span>Delete Isolate</span>
                  </button>
                  <button mat-menu-item (click)="editLot(lot)">
                    <mat-icon class="text-black">edit</mat-icon>
                    <span>Edit Isolate</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="addBatchFor(lot.LotId)">
                    <mat-icon class="text-black">add</mat-icon>
                    <span>Add Batch For Isolate</span>
                  </button>
                </mat-menu>
              </div>
          </ng-template>
          <app-batch-list class="ag-theme-material full-height" [addBatchEvent]="newBatchEventsSubject.asObservable()" [lot]="lot"></app-batch-list>
        </mat-tab>
      </div>
    </mat-tab-group>
  </div>
</div>


