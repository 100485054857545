<div class="attach-view-edit full-height full-width">
  <mat-card class="full-height full-width" *ngIf="attachment">
    <mat-card-header>
      <mat-card-title>{{ Title }}</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content style="height: 65vh;">
      <div class="full-width flex-title">
        <button mat-button (click)="Prev()"> <mat-icon>keyboard_arrow_left</mat-icon> </button>
        <mat-checkbox [(ngModel)]="IsPublic">IsPublic</mat-checkbox>&nbsp;
        <app-brand-select [(brand)]="Brand" ngDefaultControl></app-brand-select>
        <button mat-button  (click)="Next()"> <mat-icon>keyboard_arrow_right</mat-icon> </button>
      </div>
      <ng2-pdfjs-viewer #pdfViewerOnDemand (onDocumentLoad)="pdfLoaded($event)" pdfSrc="/assets/pdf/gw-logo.pdf" zoom="page-width"></ng2-pdfjs-viewer>
    </mat-card-content>
  </mat-card>
</div>
