import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { BatchService } from '../services/batch.service';
import { AttachmentService } from '../services/attachment.service';
import { RawLotService } from '../services/raw-lot.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { Batch } from '../classes/batch';
import { RawLot } from '../classes/raw-lot';
import { Attachment } from '../classes/attachment';
import { map } from 'rxjs/operators';
import { DataService } from '../services/data.service';
import { AttachmentViewEditComponent } from '../attachment-view-edit/attachment-view-edit.component';
import { BaseGraphqlServiceService } from '../services/base-graphql-service.service';


@Component({
  selector: 'app-lot-unknown',
  templateUrl: './lot-unknown.component.html',
  styleUrls: ['./lot-unknown.component.scss']
})
export class LotUnknownComponent implements OnInit, AfterViewInit {

  rawLots: Observable<RawLot[]>;
  attachments: Observable<Array<Attachment>>;
  displayedColumns: string[] = ['Action', 'BatchId', 'IsPublic', 'Lot', 'Attachments'];
  datasource: MatTableDataSource<Batch>;
  currentItem: Batch;
  currentAttachment: Attachment;
  protected selectedAttachmentSource: Subject<Attachment> = new Subject<Attachment>();
  public AttachmentSelected$ = this.selectedAttachmentSource.asObservable();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('pdf', {static: true}) pdf: AttachmentViewEditComponent;
  @Input()
  lotid = 'UNKNOWN';

  constructor(
    private gqlService: BaseGraphqlServiceService,
    private batchService: BatchService,
    private attachmentService: AttachmentService,
    private lotService: RawLotService,
    private data: DataService
  ) {
    this.data.newKeyboardNavMessage$.subscribe( event$ => this.keyboardNavEvent(event$) );
    this.batchService.Deleted$.subscribe( deletedBatch => this.batchRemoved(deletedBatch) );
    this.lotService.Added$.subscribe( newLot => this.lotAdded(newLot) );
   }

  ngOnInit() {
    //this.rawLots = this.lotService.getAll();
    this.rawLots = this.gqlService.getAllLots();
    this.rawLots.subscribe( rawLots => {
      let batches = rawLots.find( i => i.LotId === "UNKNOWN").Batches;
      this.datasource = new MatTableDataSource( batches );
      this.currentItem = this.datasource.data[0];
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.datasource){
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
      }
    });
  }

  keyboardNavEvent($event: KeyboardEvent) {
    // TODO Handle Arrow keys for selection
  }

  highlight(element: Element) {
    element.highlighted = !element.highlighted;
  }

  setCurrentItem( $event: MouseEvent, batch: Batch ) {
    this.currentItem = batch;
    if ( batch.Attachments && batch.Attachments.length > 0) {
      this.showAttachment( batch.Attachments[0] );
    }
    const sourceElement: any = $event.currentTarget;
    this.highlight( sourceElement );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
    if (this.datasource.paginator) {
      this.datasource.paginator.firstPage();
    }
  }

  lotChanged( batch: Batch ) {
    this.batchService.update( batch.BatchId, batch )
    .subscribe( updatedBatch => {
      if ( updatedBatch.LotId !== this.lotid ) {
        this.datasource.data = this.datasource.data.filter( forBatch => forBatch.BatchId !== updatedBatch.BatchId );
      }
    });
  }

  saveBatch( batch: Batch ) {
    this.batchService.update( '', batch )
    .subscribe(console.log);
  }

  batchRemoved( batch: Batch ) {
    // remove batch from array
    const newBatchSet = this.datasource.data;
    console.log(`removing map(batches) ${JSON.stringify(this.datasource.data)}`);
    for ( const [i, b] of newBatchSet.entries() ) {
      if ( b.BatchId === batch.BatchId ) {
        console.log(`Found and Removing batch ${b.BatchId}`);
        newBatchSet.splice( i, 1 );
        this.datasource.data = newBatchSet;
      }
    }
  }

  keyboardNav( direction: string ) {
    window.alert( direction );
  }

  showAttachment( att: Attachment ) {
    this.selectedAttachmentSource.next(att);
    this.currentAttachment = att;
  }

  lotAdded( newLot: RawLot ) {
    this.rawLots.pipe(
      map( rawLots => {
        // Add new Lot to local Lot collection
        console.log(`map(RawLots) ${rawLots}`);
        rawLots.push(newLot);
      })
    );
  }

  previousAttachment( $event: any ) {
    console.log($event);
    const attachment = $event;
    if ( this.currentItem.Attachments ) {
      let attachmentIndex =  this.currentItem.Attachments.indexOf(attachment);
      if ( attachmentIndex > 0 ) {
        this.currentAttachment = this.currentItem.Attachments[--attachmentIndex];
      }
    } else {
      return null;
    }
  }

  nextAttachment( $event: any ) {
    console.log($event);
    const attachment = $event;
    if ( this.currentItem.Attachments ) {
      let attachmentIndex =  this.currentItem.Attachments.indexOf(attachment);
      if ( this.currentItem.Attachments.length > ++attachmentIndex ) {
        this.currentAttachment = this.currentItem.Attachments[attachmentIndex];
      }
    } else {
      return null;
    }
  }

  promoteToLot( batch: Batch ) {
    // Create Lot from Batch
    console.log(`Promoting ${batch.BatchId} to RawLot`);
    this.lotService.promoteToLot( batch ).subscribe ( newLot => {
      console.log(newLot);
     });
  }

}

export interface Element {
  checked: boolean;
  name: string;
  position: number;
  weight: number;
  symbol: string;
  highlighted?: boolean;
  hovered?: boolean;
}
