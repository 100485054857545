import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AttachmentService } from '../services/attachment.service';
import { Attachment } from '../classes/attachment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-attachment-view-edit',
  templateUrl: './attachment-view-edit.component.html',
  styleUrls: ['./attachment-view-edit.component.scss']
})
export class AttachmentViewEditComponent implements OnInit {

  @ViewChild('pdfViewerOnDemand')
  pdfViewerOnDemand: any;

  @Output()
  changed = new EventEmitter<Attachment>();

  @Output()
  persisted = new EventEmitter<Attachment>();

  @Output()
  onprevious = new EventEmitter<Attachment>();

  @Output()
  onnext = new EventEmitter<Attachment>();

  data: BehaviorSubject<Attachment>;
  att: Attachment;

  defaultAttachment: Attachment;

  constructor(
    private attachmentService: AttachmentService
  ) {
    this.att = this.defaultAttachment = new Attachment({
      Url: '/assets/pdf/gw-logo.pdf',
      IsPublic: false
    });
  }

  ngOnInit() {
    this.data = new BehaviorSubject<Attachment>(this.att);
    this.data.subscribe( attachment => this.onDataChanged(attachment));
  }

  onDataChanged( changedAttachment: Attachment ) {
    if ( changedAttachment !== this.defaultAttachment ) {
      this.changed.next(changedAttachment);
      this.attachmentService.updateAttachment( changedAttachment )
      .subscribe( result => {
        console.log(result);
        this.persisted.next( result );
       });
    }
  }

  Prev() {
    if ( this.att !== this.defaultAttachment ) {
      this.onprevious.emit(this.att);
    }
  }

  Next() {
    if ( this.att !== this.defaultAttachment ) {
      this.onnext.emit(this.att);
    }
  }

  pdfLoaded(evt: any){

  }

  @Input()
  set attachment( value: Attachment ) {
    if ( value ) {
      this.att = value;
      if ( this.pdfViewerOnDemand ) {
        this.pdfViewerOnDemand.pdfSrc = this.att.Url;
        this.pdfViewerOnDemand.refresh();
      }
    }
  }

  get attachment(): Attachment {
    return this.att;
  }

  get IsPublic(): boolean {
    return this.att.IsPublic;
  }

  set IsPublic( value: boolean ) {
    this.att.IsPublic = value;
    this.data.next(this.att);
  }

  get Brand(): string {
    return this.att.Brand;
  }

  set Brand( value: string ) {
    /* detect if the brand is rational
      if( ! environment.brands.includes( { Name: value } ) ) {
      throw new Error(`There is no Brand named: ${value}`);
    }*/
    this.att.Brand = value;
    this.data.next(this.att);
  }

  get Notes(): string {
    return this.att.Notes;
  }

  set Notes( value: string ) {
    this.att.Notes = value;
    this.data.next(this.att);
  }

  get Title(): string {
    return this.att.Title;
  }

  set Title( value: string ) {
    this.att.Title = value;
    this.data.next(this.att);
  }

}
