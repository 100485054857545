import { Component, OnInit } from '@angular/core';
import { RawLot } from '../classes/raw-lot';
import { Observable, Subject } from 'rxjs';
import { RawLotService } from '../services/raw-lot.service';
import { UserService } from '../services/user.service';
import { environment } from '../../environments/environment';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Batch } from '../classes/batch';
import { BatchComponent } from '../batch/batch.component';
import { RawLotComponent } from '../raw-lot/raw-lot.component';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-lot-list',
  templateUrl: './lot-list.component.html',
  styleUrls: ['./lot-list.component.scss']
})
export class LotListComponent implements OnInit {

  public newBatchEventsSubject: Subject<string> = new Subject<string>();

  env = environment;
  selectedIndex: number;

  rawLots: Observable<Array<RawLot>>;
  batchCount = 3;

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private lotService: RawLotService ) {

  }

  ngOnInit() {
    this.rawLots = this.lotService.getAll();
  }


  selectedTabChange(event: MatTabChangeEvent){
    let selectedTab = event.tab;

  }

  deleteLot(lot: RawLot) {
    alert(`Delete: ${lot.LotId} not yet implemented.`);
  }

  editLot( lot: RawLot) {
    this.openLotDialog( lot );
  }

  addBatchFor( lotId: string) {
    this.newBatchEventsSubject.next(lotId);
  }

  openBatchDialog( editBatch: Batch ) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      batch: editBatch
    };

    this.dialog.open(BatchComponent, dialogConfig);
  }

  openLotDialog( editLot: RawLot ) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      lot: editLot
    };

    const dialogRef = this.dialog.open(RawLotComponent, dialogConfig);

    dialogRef.afterClosed()
    .subscribe( lot => {
      if ( lot != null ) {
        this.rawLots = this.lotService.getAll();
      }
    });
  }

  addLot() {
    this.openLotDialog(new RawLot());
  }
}
