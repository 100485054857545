import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '../classes/user';
import { DataService } from '../services/data.service';
import { filter } from 'rxjs/operators';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{

  @Input()
  User: User;

  @Input()
  Authenticated: boolean;

  @Input()
  Busy: boolean;

  @Output()
  onSignIn = new EventEmitter<void>();

  @Output()
  onSignOut = new EventEmitter<void>();

  currentTabValues = [
    {
      path: '/qwerty',
      routerLink: 'qwerty',
      name: 'Qwerty',
      icon: 'sticky_note_2',
      groups: 'stasis-admin stasis-qwerty'
    },
    {
      path: '/hello',
      routerLink: 'hello',
      name: 'Swell',
      icon: 'label',
      groups: 'stasis-admin stasis-swell'
    },
    {
      path: '/brand',
      routerLink: 'brand',
      name: 'Brand',
      icon: 'copyright',
      groups: 'stasis-admin'
    },

  ];

  currentTab: any;

  constructor(private data: DataService,
    private router:Router,
    private zone: NgZone ) {
    this.Busy = false;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
    .subscribe( (event: NavigationEnd) => {
      this.currentTab = this.lookupAppForUrl(event.urlAfterRedirects);
    } )
  }

  lookupAppForUrl(url:string) {
    url = url.replace(/\?.+/,'');
    return this.currentTabValues.find(x => x.path == url);
  }

  ngOnInit() {
    this.data.Busy.subscribe( busy => {
      //this.zone.run( () => {this.Busy = busy;});
    });
  }

  signOut() {
    this.onSignOut.emit(null);
  }

  signIn() {
    this.onSignIn.emit(null);
  }

}
