import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BatchService } from '../services/batch.service';
import { GridOptions } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { Batch } from '../classes/batch';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { BatchComponent } from '../batch/batch.component';
import { RawLot } from '../classes/raw-lot';


@Component({
  selector: 'app-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.scss']
})
export class BatchListComponent implements OnInit, OnDestroy {

  @Input()
  addBatchEvent = new Observable<any>();
  private batchEventsSubscription: any;

  @Input()
  _lot: RawLot;
  gridOptions: GridOptions;

  constructor(
    private dialog: MatDialog,
    private batchService: BatchService ) {
      this.gridOptions = {
        rowHeight: 48,
      } as GridOptions;
      this.gridOptions.columnDefs = [
        {
          headerName: 'Batch ID',
          field: 'BatchId',
          resizable: true,
          sortable: true
        },
        {
            headerName: 'Product',
            field: 'Product',
            resizable: true,
            sortable: true

        },
        {
          headerName: 'Creation Date',
          field: 'CreationDate',
          resizable: true,
          sortable: true
        },
        {
          headerName: 'Is Public',
          field: 'IsPublic',
          width: 95,
          resizable: true,
          filter:true,
          cellRenderer: params => {
            return `<div class="text-center mat-checkbox-inner-container mx-auto"> \
                      <input class="form-check-input" readonly disabled type='checkbox' \
                      ${params.value ? 'checked' : ''} /> \
                    </div>`;
          }
        },
        {
          headerName: 'Notes',
          resizable: true,
          field: 'Notes'
        }
      ];

  }

  ngOnInit() {
    this.setBatchesForLot( this.lot.LotId );
    this.batchEventsSubscription = this.addBatchEvent.subscribe( lotId => {
      if (this.lot.LotId === lotId) {
        this.addNewBatch( lotId );
      }
    });
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.

  }

  refreshGrid() {
    if( this.gridOptions.api != null ) {
      this.gridOptions.api.setRowData( this.lot.Batches );
    }
  }

  @Input()
  set lot( newLot: RawLot ){
    this._lot = newLot;
  }

  get lot() {
    return this._lot;
  }

  addNewBatch( lotId ) {
    const batch = new Batch();
    batch.LotId = lotId;
    this.openBatchDialog(batch);
  }

  setBatchesForLot( lotId: string ) {
    this.batchService.getAll( lotId ).subscribe( (bs) => {
      this.lot.Batches = bs;
      this.refreshGrid();
    });
  }

  onCellDoubleClicked($event: any) {
    this.openBatchDialog($event.data);
  }

  openBatchDialog( editBatch: Batch ) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      batch: editBatch
    };

    const dialogRef = this.dialog.open(BatchComponent, dialogConfig);

    dialogRef.afterClosed()
    .subscribe( batch => {
      if ( batch !== null && batch !== undefined ) {
        this.setBatchesForLot( batch.LotId );
      }
    });

  }


}
